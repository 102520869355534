import {
    createBrowserRouter,
    Link,
  } from "react-router-dom";

  import Landing from '../components/Landing';
  import Benefits from '../components/Benefits';



  const Router = createBrowserRouter([
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "contact",
      element: <div>Contact</div>,
    },
    {
      path: "Benefits",
      element: <Benefits />,
    },
    {
      path: "about",
      element: <div>About</div>,
    },
  ]);

  export default Router;